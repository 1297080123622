<template>
  <div v-if="visible" v-loading="loading" element-loading-text="命令提交中">
    <div class="device">
      <span style="line-height: 30px;">UID:</span>
      <el-input v-model="uid" size="small" placeholder="请输入UID"></el-input>

      <el-button type="primary" size="small" @click="commitUpdate()">
        <i class="el-icon-upload el-icon"></i>升级
      </el-button>
      <el-button type="primary" size="small" plain @click="getjcoDoneTime">
        <i class="el-icon-finished"></i>查询升级进度
      </el-button>
      <el-button type="primary" size="small" plain @click="toIotServer">
        <i class="el-icon-more-outline"></i>查看更多设备信息
      </el-button>
      <el-button type="warning" size="small" style="float:right;margin-right: 50px;" @click="commitUpdate('testUpdata')">
        <i class="el-icon-upload"></i>升级测试版
      </el-button>
    </div>
    <div class="res">
      <div>指令地址：<span>{{resMes.posturl}}</span></div>
      <el-divider></el-divider>

      <div>状态：<span>{{resMes.postRes}}</span></div>
      <el-divider></el-divider>

      <div>结果：<span>{{resMes.resDesc}}</span></div>

    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      firmwares: [],
      folder: "",
      testFolder: "",
      visible: true,
      uid: "",
      resMes: { posturl: "", postRes: "", resDesc: "" },
      loading: false
    }
  },
  created() {
    this.getFirmwares()
  },

  mounted() {
    this.ws.addCallback('firmwaresAck', (res) => {
      // console.log("firmwares:", this.firmwares);
      this.firmwares = res.firmwares
    })
  },

  methods: {
    //获取固件文件夹
    getFirmwares() {
      var json = {
        "cmd": "firmwares"
      }
      this.ws.send(json)
    },
    commitUpdate(state) {
      if (this.uid) {
        let firmwares = this.firmwares
        if (firmwares.length != 0) {  //检查接口是否返回
          let uidPrefix = this.uid.substr(0, 6)
          uidPrefix = uidPrefix.replace(/[^A-Z]+/ig, "")
          console.log("uidPrefix", uidPrefix);
          console.log("firmwares", firmwares);
          firmwares.forEach((item) => {
            if (item.UIDPrefix == uidPrefix) {
              if (state == 'testUpdata') {
                this.folder = item.urlTest    //测试升级
              } else {
                this.folder = item.url      //正式升级
              }
              this.getjcoDate(state)
            }
          })
        } else {
          this.common.cToast(this, "升级失败，请检查接口");
        }
      } else {
        this.common.cToast(this, "请输入设备UID");
      }

    },
    getjcoDate(state) {
      let url = "https://iot.jjyip.com/JcoUpdate.xml?url=http://iot.jjyip.com" + this.folder + "&uid=" + this.uid
      let that = this
      that.resMes.posturl = url
      // console.log("url:", url);
      axios.get(url)
        .then(function (res) {
          // console.log(res.data);
          if (res.status == 200) {
            that.resMes.postRes = that.uid + " " + "升级请求发送成功" + (state == 'testUpdata' ? "(测试版)" : '')
            let data = that.getXmlDocumentByXmlString(res.data)
            let resErr = data.getElementsByTagName("Error")[0].innerHTML
            if (resErr == -1) {
              that.resMes.resDesc = that.uid + " " + (data.getElementsByTagName("Desc")[0].innerHTML == "device offline?" ? "执行失败，设备未上线" : "执行失败，未能找到此UID设备")
            } else {
              that.loading = true
              setTimeout(() => {
                that.getjcoDoneTime()
              }, 8000);
            }

          } else {
            that.resMes.postRes = "请求失败"
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },


    getjcoDoneTime() {
      if (this.uid) {
        let url = "https://iot.jjyip.com/updater.json?uid=" + this.uid
        let that = this
        axios.get(url)
          .then(function (res) {
            let doneTime = res.data[that.uid].linux.doneTime
            if (doneTime != '') {
              that.resMes.resDesc = that.uid + " " + "升级命令已成功提交到设备：" + doneTime
            } else {
              that.resMes.resDesc = that.uid + " " + "升级命令已成功提交到平台，设备上线后会开始升级"
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        that.loading = false
      }

    },

    toIotServer() {
      if (this.uid) {
        let url = "https://iot.jjyip.com/proc.xml?url=IotServer[" + this.uid + "]"
        window.open(url, "_blank");
      } else {
        this.common.cToast(this, "请输入设备UID");
      }

    },
    getXmlDocumentByXmlString(xmlString) {
      var xmlDoc = null;
      if (window.DOMParser) {
        var parser = new DOMParser();
        xmlDoc = parser.parseFromString(xmlString, "text/xml");
      } else {
        //IE
        xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
        xmlDoc.async = "false";
        xmlDoc.loadXML(xmlString);
      }
      return xmlDoc;
    }
  }

}
</script>
<style lang="scss" scoped>
.device {
  font-size: 14px;
}
.res {
  margin-top: 20px;
  min-height: 145px;
}
.content {
  margin-top: 20px;
  min-height: 145px;
}
.el-input {
  width: 140px;
  margin: 0 20px;
  font-size: 13px;
}
.el-divider {
  margin: 12px 0;
}
.el-checkbox:last-of-type {
  margin-right: 20px;
}
[class^="el-icon-"],
[class*=" el-icon-"] {
  margin: 0 6px 0 6px;
}
.el-checkbox {
  margin-right: 0px !important;
}
</style>